import { Injectable } from '@angular/core';
import { DeleteRequest, FeedbackRequest, FeedbackTypeItem, SuggestionRequest } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { Subject, lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { ResultHelper } from '../../../../../../../../goldstar-share/src/app/common/result-extension';
import { Result } from '../../../../../../../../goldstar-share/src/app/models/models';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class FeedbackService {
	public feedbackTypeList: FeedbackTypeItem[] = [];

	updatedFeedbackItem = new Subject<FeedbackRequest>();
	mySuggestionCount: number = 0;
	suggestionReceivedCount: number = 0;
	archivedSuggestionCount: number = 0;
	constructor(private apiV2: ApiService) {}

	public async fetchAllFeedbackTypes(): Promise<Result<FeedbackTypeItem[]>> {
		if (this.feedbackTypeList.length == 0) {
			return await lastValueFrom(this.apiV2.getFeedbackType()).then((response) => {
				if (response.isSuccess) {
					this.feedbackTypeList = response.data?.items ?? [];
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<FeedbackTypeItem[]>('Failed to fetch feedback type.');
				}
			});
		}
		return ResultHelper.successResponse(this.feedbackTypeList ?? []);
	}

	public async saveFeedback(feedback: FeedbackRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.feedbackAdd({ body: feedback }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	/**
	 * Deletes a particular feedback from database
	 * @param request
	 * @returns
	 */
	public async deleteFeedback(request: DeleteRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.feedbackDelete({ body: request })).then((deleteResponse) => {
			if (deleteResponse.isSuccess) {
				return ResultHelper.successResponse('Successfully deleted Feedback');
			} else {
				return ResultHelper.failedResponse('Failed to delete Feedback');
			}
		});
	}

	//method for handling whitespace
	trimValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value) {
				let trimmedValue = control.value.trim();
				if (trimmedValue === '') {
					return { required: true };
				} else {
					return null;
				}
			}
			return null;
		};
	}

	public async saveSuggestion(suggestion: SuggestionRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.suggestionAdd({ body: suggestion }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}
	public async hasSuggestions(internalUserGUID: string): Promise<Result<boolean>> {
		return await lastValueFrom(
			this.apiV2.mySuggestionCount({
				internalUserGUID: internalUserGUID,
			})
		)
			.then(async (response) => {
				if (response.isSuccess) {
					this.mySuggestionCount = response.data?.mySuggestionViewCount ?? 0;
					this.suggestionReceivedCount = response.data?.receivedSuggestionViewCount ?? 0;
					this.archivedSuggestionCount = (response.data?.archivedMySuggestionViewCount ?? 0) + (response.data?.archivedReceivedSuggestionViewCount ?? 0);
					return ResultHelper.successResponse(this.mySuggestionCount != 0 || this.suggestionReceivedCount != 0 || this.archivedSuggestionCount != 0 ? true : false);
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	public async updateReplyViewed(suggestion: SuggestionRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.updateSuggestionReplyView({ body: suggestion }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}
}
